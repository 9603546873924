<template>
  <div> 
    <div class="sub-title underlined pb-2 mt-4">
      申込用紙提出の手続き
    </div>
    <div class="mt-5 ml-3 mr-3">
      <p>ご記入いただきたい書類は2種類ございます。</p>
      <p>申込用紙がお手元に届きましたら、一式をご記入の上、返信用封筒に入れてご返送ください。</p>
      <p>１）契約書への署名者の登録書</p>
      <p>バイヤ企業との取引を始めるためには共通取引基本契約書及び特約条項に電子署名をいただく必要がありますので、署名者登録書に契約書への署名者をご記入、押印して、ご返送ください。記入、押印して、ご返送ください。</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>