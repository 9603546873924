<template>
  <div>
    <b-alert variant="danger" show class="mt-2">
      <p class="m-0">
        <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
        ご登録情報で再度ご確認いただきたい項目があります。以下の該当設定メニューを選択し、ご確認、編集をお願いします。
      </p>
    </b-alert>
  </div>
</template>

<script>
export default {
  
}
</script>