<template>
  <div>
    <div class="todo">
      <div class="todo-body">
        <div class="todo-item" v-if="hasRole('S001') || hasRole('ST01')">
          <div class="d-inline-block text-center todo-label">
            <div class="circle-title"><font-awesome-icon icon="file-alt"></font-awesome-icon></div>
            <br/>
            <span class="counter-tag no-border">{{$t('SUPPLIER_HOME.LB_ESTIMATE_TODO')}}</span>
          </div>
          <div class="d-inline-block text-center ml-2 todo-counter">
            <a href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/Estimate/BeforeAnswer?' + singleSignOnParam)">
              <div class="half-circle is-active">{{todos.estimateRequestCount}}</div>
              <br/>
              <span class="counter-tag is-orange">{{$t('COMMON.UNANSWERED')}}</span>
            </a>
          </div>
          <div class="d-inline-block text-center ml-2 todo-counter">
            <a href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/Estimate/AfterAnswer?' + singleSignOnParam)">
              <div class="half-circle">{{todos.estimateAnswerCount}}</div>
              <br/>
              <span class="counter-tag is-green">{{$t('COMMON.ANSWERED')}}</span>
            </a>
          </div>
          <div class="d-inline-block text-center ml-2 todo-counter">
            <a href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/Estimate/AfterAnswer?isOrderFlow&' + singleSignOnParam)">
              <div class="half-circle">{{todos.estimateSelectedCount}}</div>
              <br/>
              <span class="counter-tag is-green">{{$t('COMMON.ESTIMATE_ACCEPTED')}}</span>
            </a>
          </div>
        </div>
      
        <div class="todo-item" v-if="status.spotSupplier === false && hasRole('S001')">
          <div class="d-inline-block text-center todo-label">
            <div class="circle-title"><font-awesome-icon icon="truck"></font-awesome-icon></div>
            <br/>
            <span class="counter-tag no-border">{{$t('SUPPLIER_HOME.LB_ORDER_TODO')}}</span>
          </div>
          <div class="d-inline-block text-center ml-2 todo-counter">
            <a href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/AcceptingOrder?status=260&' + singleSignOnParam)">
              <div class="half-circle is-active">{{todos.waitingOrderCount}}</div>
              <br/>
              <span class="counter-tag is-orange">{{$t('SUPPLIER_HOME.LB_WAITING_RECEIVE')}}</span>
            </a>
          </div>
          <div class="d-inline-block text-center ml-2 todo-counter">
            <a href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/WaitingDelivery?status=290&' + singleSignOnParam)">
              <div class="half-circle is-active">{{todos.waitingDeliveryCount}}</div>
              <br/>
              <span class="counter-tag is-orange">{{$t('SUPPLIER_HOME.LB_WAITING_DELIVERY')}}</span>
            </a>
          </div>
          <div class="d-inline-block text-center ml-2 todo-counter">
            <a href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/CompletedDelivery?status=300&' + singleSignOnParam)">
              <div class="half-circle">{{todos.waitingBuyerAcceptanceCount}}</div>
              <br/>
              <span class="counter-tag is-green">{{$t('SUPPLIER_HOME.LB_WAITING_ACCEPTANCE')}}</span>
            </a>
          </div>
          <div class="d-inline-block text-center ml-2 todo-counter">
            <a href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/CompletedAcceptance?status=330&' + singleSignOnParam)">
              <div class="half-circle">{{todos.buyerAcceptedCount}}</div>
              <br/>
              <span class="counter-tag is-green">{{$t('SUPPLIER_HOME.LB_COMPLETE_ACCEPTANCE')}}</span>
            </a>
          </div>
        </div>      

        <div class="todo-item" v-if="(hasRole('UR002') || hasRole('UR003'))" >
          <div class="d-inline-block text-center todo-label">
            <div class="circle-title"><font-awesome-icon icon="check"></font-awesome-icon></div>
            <br/>
            <span class="counter-tag no-border">{{$t('SUPPLIER_HOME.LB_PRODUCT_REGISTRATION_TODO')}}</span>
          </div>
          <div class="d-inline-block text-center ml-2 todo-counter">
            <a href="javascript:void(0)" @click.prevent="redirectSsoLink(cmUrl + '/ProductApplicationList?' + singleSignOnParam + '&tabId=1', false)">
              <div :class="(hasRole('UR002') && todos.numberWaitApplicant > 0) ? 'is-active' : ''" class="half-circle ">{{todos.numberWaitApplicant}}</div>
              <br/>
              <span :class="(hasRole('UR002') && todos.numberWaitApplicant > 0) ? 'is-orange' : 'is-green'" class="counter-tag">{{$t('SUPPLIER_HOME.LB_WAITING_APPLICATION')}}</span>
            </a>
          </div>
          <div class="d-inline-block text-center ml-2 todo-counter">
            <a href="javascript:void(0)" @click.prevent="redirectSsoLink(cmUrl + '/ProductApplicationList?' + singleSignOnParam + '&tabId=2', false)">
              <div :class="(hasRole('UR003') && todos.numberWaitApplicant > 0) ? 'is-active' : ''" class="half-circle">{{todos.numberInternalApprove}}</div>
              <br/>
              <span :class="(hasRole('UR003') && todos.numberWaitApplicant > 0) ? 'is-orange' : 'is-green'" class="counter-tag">{{$t('SUPPLIER_HOME.LB_INTERNAL_APPROVE')}}</span>
            </a>
          </div>
          <div class="d-inline-block text-center ml-2 todo-counter">
            <a href="javascript:void(0)" @click.prevent="redirectSsoLink(cmUrl + '/ProductApplicationList?' + singleSignOnParam + '&tabId=3', false)">
              <div class="half-circle">{{todos.numberExternalApprove}}</div>
              <br/>
              <span class="counter-tag is-green">{{$t('SUPPLIER_HOME.LB_EXTERNAL_APPROVE')}}</span>
            </a>
          </div>
          <div class="d-inline-block text-center ml-2 todo-counter">
            <a href="javascript:void(0)" @click.prevent="redirectSsoLink(cmUrl + '/ProductApplicationList?' + singleSignOnParam + '&tabId=4', false)">
              <div :class="(hasRole('UR002') && todos.numberInternalReject > 0) ? 'is-active' : ''" class="half-circle">{{todos.numberInternalReject}}</div>
              <br/>
              <span  :class="(hasRole('UR002') && todos.numberInternalReject > 0) ? 'is-orange' : 'is-green'" class="counter-tag">{{$t('SUPPLIER_HOME.LB_INTERNAL_REJECT')}}</span>
            </a>
          </div>          
          <div class="d-inline-block text-center ml-2 todo-counter">
            <a href="javascript:void(0)" @click.prevent="redirectSsoLink(cmUrl + '/ProductApplicationList?' + singleSignOnParam + '&tabId=5', false)">
              <div class="half-circle">{{todos.numberExternalReject}}</div>
              <br/>
              <span class="counter-tag is-green">{{$t('SUPPLIER_HOME.LB_EXTERNAL_REJECT')}}</span>
            </a>
          </div>
        </div>
        <div class="todo-item fill">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyModel from '@/model/CompanyInfo.js';
import { mapGetters } from 'vuex';

export default {
  props: ['status'],
  data: function(){
    return {
      todos: {
        estimateRequestCount: 0,
        estimateAnswerCount: 0,
        estimateSelectedCount: 0,
        waitingOrderCount: 0,
        waitingDeliveryCount: 0,
        waitingBuyerAcceptanceCount: 0,
        buyerAcceptedCount: 0
      },
      j2Url: process.env.VUE_APP_J2_URL,
      cmUrl: process.env.VUE_APP_CM_URL,
      jti: this.$store.state.user.jtiToken
    }
  },
  mounted: function(){
    this.load();
  },
  methods: {
    load(){
      CompanyModel.getTodos(this.$store.state.user.companyCode, 
                            this.$store.state.user.jtiToken,
                            this.$store.state.user.companyDisplayCode,
                            this.$store.state.user.displayCode)
      .then(result => {
        if(result.data){
          this.todos = result.data;
        }
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    },
    redirectSsoLink(ssoLink, isCurrentTab = true) {
      if (!ssoLink) {
        return;
      }
      this.$store.dispatch('redirectSingleSignOnLink', {ssoLink, isCurrentTab})
    },
    hasRole(roleCode){
      if(!this.$store.state.logged) return false;
      for(var i=0; i < this.$store.state.user.roles.length; i++){
        
        if(this.$store.state.user.roles[i].roleCode === roleCode){
          return true;
        }
      }
      return false;
    },
  },
  computed: {
    ...mapGetters(["singleSignOnParam"])
  }
}
</script>

<style lang="scss" scoped>
  .todo{
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    .todo-body{
      flex: 1;
      display: flex;
      align-items: center;
      a{
        color: #000;
        text-decoration: none;
      }
      .todo-item {
        background: #EDF1F6;
        padding: 1rem;
        border-radius: 0 0 2.5px 2.5px;
        min-height: 92px;
        margin-right:2.5px;
        &:last-child{
          margin-right: 0;
        }
        .todo-label{
          border-right: 1px solid #cccccc; 
          min-width: 89px;
        }
        .todo-counter{
          min-width: 60px;
        }
        &.fill{
          flex: 1;
        }
      }
    }
  }

  .center-items{
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .circle-title{
    display: inline-block;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    padding-top: 6px;
    background: $biz-primary;
    color: #FFF;
  }

  .half-circle {
    display: inline-block;
    background: url("~@/assets/images/icon_meter_black_bg.png");
    height: 32px;
    width: 32px;
    padding-top: 12px;
    padding-left: 18px;
    font-weight: bold;
    &.is-active{
      background: url('~@/assets/images/icon_meter_red_bg.png');
    }
  }
  .counter-tag{
    border: 1px solid;
    border-radius: 2px;
    padding: 0px 4px 0px 4px;
    font-size: 12px;
    &.no-border{
      border: none;
      font-weight: bold;
    }
    &.is-green{
      border-color: #437643;
      color: #437643;
    }
    &.is-orange{
      border-color: #ff4500;
      color: #ff4500 !important;
      background:none !important;
    }
    &.is-pink{
      border-color: #ef5286;
      color: #ef5286;
    }
  }
</style>