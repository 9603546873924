<template>
  <div>
    <div v-if="isLoaded">
      <div v-if="(hasRole('S001') || hasRole('ST01') || hasRole('UR002') || hasRole('UR003')) && (status.spotSupplier === true || status.completed)">
        <Todo :status="status"/> 
      </div>
      <!--TODO:// Alert will be shown as default for open suppliers and go away once its loaded.-->
      <b-alert variant="warning" :show="false && (!status.openSupplier && !status.disabledOpen && status.completed)" dismissible fade> 
        <a href="http://oyutechnology.com:3000/kizon.html" target="_blank">新しいバイヤ企業とお取引をしませんか？</a><!--TODO change to environment variable-->
      </b-alert>
      
      <!-- BUG2023-2043: add style -->
      <div class="page" :style="(footerItems && footerItems.length > 0) ? 'padding-bottom: 60px;' : ''">
        <template v-if="status.spotSupplier === false">
          <div v-if="(status.reExaminationRequested || status.buyerReExaminationRequested) && !status.examinationVerified">
            <ReExamination/>
          </div>
          <div class="px-1 pt-2 pb-5" v-if="!status.completed">
            <RegistrationFlow :status="status"/>
            <div v-if="(!status.registrationCompleted || status.buyerExaminationCompleted) && (hasRole('S999') || hasRole('SB20'))" class="text-center mt-5">
              <j-button to="/supplier/registration" size="lg" variant="primary">情報登録を継続</j-button>
            </div>
          </div>
          <div v-if="status.examinationCompleted && !status.addressVerified && !status.completed && status.openSupplier">
            <AddressVerification/>
          </div>
          <div class="pl-1 pr-1 pt-5 pb-5" v-if="!status.registrationCompleted">
            <div class="sub-title lg underlined">
              <h1>登録済みの項目を編集</h1>
            </div>
          </div>
        </template>
        
        <MainMenu :isOpenSupplier="status.openSupplier" :status="status"/>
        <div class="word-break" id="Notification">
          <h2>{{ $t("MAIN_MENU.LB_NOTICE") }}</h2>
          <div v-for="item in noticeResponse.items" :key="item.buyerCompanyGroupDisplayCode" class="mb-3 ml-3">
            <span>{{ item.buyerCompanyGroupDisplayCode }}{{ $t("MAIN_MENU.LB_NOTICE_OF") }}</span>
            <template v-if="item.noticeList.length <= 5">
              <div v-for="notice in item.noticeList" :key="notice.code" class="mb-3">
                <span>
                  ・
                  {{ $moment(notice.startDate).format("YYYY-MM-DD") }}
                </span>
                <div class="ml-3">
                  <span class="d-flex">{{ notice.title }}</span>
                  <span>
                    (→
                    <a href="javascript:;" @click="openNoticeListView(notice.code)">
                      <b>{{ $t("MAIN_MENU.LB_HERE") }}</b>
                    </a>
                    {{ $t("MAIN_MENU.LB_CLICK") }})
                  </span>
                </div>
              </div>
            </template>
            <template v-else>
              <div v-for="i in 5" :key="i" class="mb-3">
                <span>
                  ・
                  {{ $moment(item.noticeList[i - 1].startDate).format("YYYY-MM-DD") }}
                </span>
                <div class="ml-3">
                  <span class="d-flex">{{ item.noticeList[i - 1].title }}</span>
                  <span>
                    (→
                    <a href="javascript:;" @click="openNoticeListView(item.noticeList[i - 1].code)">
                      <b>{{ $t("MAIN_MENU.LB_HERE") }}</b>
                    </a>
                    {{ $t("MAIN_MENU.LB_CLICK") }})
                  </span>
                </div>
              </div>
              <div v-show="item.isShowMore">
                <div v-for="i in (item.noticeList.length - 5)" :key="i" class="mb-3">
                  <span>
                    ・
                    {{ $moment(item.noticeList[(i + 5) - 1].startDate).format("YYYY-MM-DD") }}
                  </span>
                  <div class="ml-3">
                    <span class="d-flex">{{ item.noticeList[(i + 5) - 1].title }}</span>
                    <span>
                      (→
                      <a href="javascript:;" @click="openNoticeListView(item.noticeList[(i + 5) - 1].code)">
                        <b>{{ $t("MAIN_MENU.LB_HERE") }}</b>
                      </a>
                      {{ $t("MAIN_MENU.LB_CLICK") }})
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-1" v-if="item.noticeList.length > 5" style="margin-left: 90px;">
                <a href="javascript:;" @click.prevent="item.isShowMore = true" v-show="!item.isShowMore">
                  <font-awesome-icon class="icon-arrow" icon="chevron-down" />
                  &nbsp;{{ $t("MAIN_MENU.LB_SHOW_MORE") }}
                </a>
                <a href="javascript:;" @click.prevent="item.isShowMore = false" v-show="item.isShowMore">
                  <font-awesome-icon class="icon-arrow" icon="chevron-up" />
                  &nbsp;{{ $t("MAIN_MENU.LB_HIDE") }}
                </a>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="modal" tabindex="-1" role="dialog" id="modalNoticeDetail" v-if="Object.getOwnPropertyNames(noticeDetail).length > 1 && isDisplayDetail" style="display: block">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document" style="width:1100px">
          <div class="modal-content word-break">
            <div class="modal-header">
              <h5 class="modal-title">{{ noticeDetail.title }}</h5>
              <button type="button" class="close" @click="hidemodalNotice">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body" style="height: 250px;overflow-y: auto;">
              <div v-if="noticeDetail.body" style="white-space: pre-line;">{{ noticeDetail.body }}</div>
              <div v-if="noticeDetail.detailUrl" class="mt-3">
                {{ $t("MAIN_MENU.LB_DETAIL_URL") }}:
                <br />
                <a class="ml-3" href="javascript:;" @click="openUrl(noticeDetail.detailUrl)">{{ noticeDetail.detailUrl }}</a>
              </div>
              <div v-if="noticeDetail.metaData && noticeDetail.metaData.length > 0" class="mt-3">
                {{ $t("MAIN_MENU.ATTACHMENT") }}:
                <br />
                <a class="ml-3" href="javascript:;" v-for="attachment of noticeDetail.metaData" :key="attachment.filePath" @click="downloadAttachment(attachment)">
                  {{ decodeURI(attachment.fileName) }}
                  <br />
                </a>
              </div>
            </div>
            <div class="modal-footer d-block text-center">
              <div>
                <button type="button" class="btn btn-fixed-width" style="background-color: #ef6e47; border-color: #ef6e47; color: #fff;" @click="hidemodalNotice">{{ $t("MAIN_MENU.BTN_CONFIRM") }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="Object.getOwnPropertyNames(noticeDetail).length > 1 && isDisplayDetail" class="modal-backdrop show"></div>
    </div>
    <!-- BUG2023-2043 -->
    <div id="buyer-footer" v-if="footerItems && footerItems.length > 0">
      <div v-for="(item, index) in footerItems" :key="index">
        <a :href="item.urlPath" target="_blank" v-if="item.isURL">{{ item.textName }}</a>
        <router-link v-else :to="{ name: 'PDFView', query: { footerSettingId: item.id, title: item.textName }}" target="_blank">
          {{ item.textName }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  
</style>
<script>
  import MainMenu from '@/components/main-menu/MainMenu.vue'
  import Todo from '@/components/dashboard/Todo.vue'
  import RegistrationFlow from '@/components/flow/RegistrationFlow.vue'
  import ReExamination from '@/components/dashboard/ReExamination.vue'
  import AddressVerification from '@/components/dashboard/AddressVerification.vue'


  import CompanyInfo from '@/model/CompanyInfo.js';
  import FooterSetting from '@/model/FooterSetting.js';

  export default {
    components: { MainMenu, Todo, RegistrationFlow, ReExamination, AddressVerification },
    data(){
      return {
        isLoaded: false,
        status: {
          registrationCompleted: true,
          buyerExaminationCompleted: true,
          completed: true
        },
        noticeResponse: {},
        noticeDetail: {},
        isDisplayDetail: false,
        // BUG2023-2043
        footerItems: [],
      }
    },
    created: function() {
      CompanyInfo.getCompanyStatus(this.$store.state.user.companyCode).then((result) => {
        this.status = result.data;
        this.isLoaded = true;
      }).catch(reason => this.$errorMessage(undefined, {reason}));

      CompanyInfo.noticeList(this.$store.state.user.companyCode).then((result) => {
        this.noticeResponse = result.data;
        this.$set(this.$store.state.user, "totalNotice", result.data.totalCount);
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    },
    mounted() {
      // BUG2023-2043
      this.getFooterSettingList();
    },
    methods: {
      hasRole(roleCode){
        if(!this.$store.state.logged) return false;
        for(var i=0; i < this.$store.state.user.roles.length; i++){
          if(this.$store.state.user.roles[i].roleCode === roleCode){
            return true;
          }
        }
        return false;
      },
      hidemodalNotice() {
        this.noticeDetail = {};
        this.isDisplayDetail = false;
        var element = document.getElementsByTagName("BODY")[0];
        element.classList.remove("modal-open");
        document.removeEventListener('mouseup', this.closeModalOnClickOutside);
      },
      showmodalNotice() {
        this.isDisplayDetail = true;
        // var element = document.getElementsByTagName("BODY")[0];
        // element.classList.add("modal-open");
        document.addEventListener('mouseup', this.closeModalOnClickOutside);
      },
      closeModalOnClickOutside(event) {
        if (event.target.id == 'modalNoticeDetail') {
          this.hidemodalNotice();
        }
      },
      openNoticeListView(code) {
        CompanyInfo.noticeDetail(this.$store.state.user.companyCode, code)
          .then(response => {
            this.noticeDetail = response.data;
          })
          .catch(() => {
            // do nothing
          });
        this.showmodalNotice();
      },
      downloadAttachment(attachment) {
        return CompanyInfo
        .getDownloadFileLink(this.$store.state.user.companyCode, attachment.filePath, attachment.fileName)
        .then(response => {
          window.location = response.data.data.url;
        })
        .catch(() => {
          // do nothing
        });
      },
      validURL(url) {
        var pattern = new RegExp('^(http|https)://'+ // protocol have http/https
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(url);
      },
      openUrl(url) {
        if (this.validURL(url)) {
          window.open(url);
        }
      },
      // BUG2023-2043
      getFooterSettingList() {
        FooterSetting
          .getFooterSettingList(this.$store.state.user.companyCode)
          .then(res => {
            this.footerItems = res.data;
            this.footerItems.forEach(item => {
              if (item.urlPath && !(item.urlPath.includes('http://') || item.urlPath.includes('https://'))) {
                item.urlPath = 'https://' + item.urlPath;
              }
            });
          });
        },
      }
  }
</script>
<style scoped>
button.close {
  background: #1d416b;
  opacity: 1;
  width: 36px;
  min-width: 36px;
  height: 36px;
  margin: 0;
  line-height: 37px;
  text-align: center;
  padding: 0;
  color: #ffffff;
  font-size: 2rem;
  border-radius: 5px;
  font-weight: normal;
  float: right;
}
.word-break {
  word-break: break-word;
}
.show {
  display: block;
}
#buyer-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #03041f;
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: center;
}
#buyer-footer div {
  white-space: nowrap;
  overflow: hidden;
  max-width: 400px;
}
#buyer-footer a {
  color: #fff !important;
  font-size: 14px;
  margin: 0 10px;
  text-decoration: none;
}
</style>